import React from 'react'
import { Slide,Fade} from 'react-awesome-reveal';

const Experience = () => {
  return (
	<Slide direction='up' triggerOnce duration={1000}>
    <section className="ftco-section" id="experience-section" style={{paddingTop:0}}>
    	<div className="container">
    		<div className="row justify-content-center py-5 mt-5">
				<div className="col-md-12 heading-section text-center ">
					<h1 className="big big-2">Experience</h1>
					<h2 className="mb-4">Experience</h2>
					<p></p>
				</div>
        	</div>
    	
			<div className="row">
				<div className="col-md-12">
					<div className="resume-wrap">
					<Fade cascade triggerOnce>
						<span className="date" style={{fontSize:'15px'}}>2023 APRIL - CURRENT</span>
						<h2 style={{fontSize:'20px'}}>Software Engineer</h2>
						<span className="position">Inqbaytor</span>
						<span className="position">Project: Ticket Gadget</span>
						<p className="mt-4">Build and maintained backend application features of the system using Laravel framework with the use of micro services. Connected with a team of backend engineers, UI engineers ,QA engineers and Tech Leads to accomplish the tasks.</p>
					</Fade>
					</div> 
				</div>
			</div>

			<div className="col-md-12">
				<div className="resume-wrap">
				<Fade cascade triggerOnce>
					<span className="date" style={{fontSize:'15px'}}>2022 MARCH - 2023 APRIL</span>
					<h2 style={{fontSize:'20px'}}>Associate Software Engineer</h2>
					<span className="position">Bellvantage</span>
					<span className="position">Project: Teawei</span>
					<p className="mt-4">Build and maintained application features of the system as a full stack engineer. Connected with the stakeholders to understand the business requirements ,communicated with cross-functional teams regarding product and design, and worked with a team of full stack engineers and QA engineers.</p>
				</Fade>
				</div> 
			</div>
		</div>
    </section>
	</Slide>
  )
}

export default Experience
