import React from 'react'
import { Slide,Fade} from 'react-awesome-reveal';

const About = () => {
  return (
	<Fade triggerOnce duration={3000}>
    <section className="ftco-about img ftco-section ftco-no-pb"  id="about-section" style={{paddingTop:0}}>
    	<div className="container">
			<div className="row justify-content-center mb-5 pb-3">
				<div className="col-md-7 heading-section text-center ">
				<h1 className="big big-2">About</h1>
				<h2 className="mb-4">About Me</h2>
				</div>
			</div>

    		<div className="row d-flex">
    			<div className="col-md-6 col-lg-5 d-flex">
					
    				<div className="img-about img d-flex align-items-stretch">
    					<div className="overlay"></div>
	    				<div className="img d-flex align-self-stretch align-items-center" style={{backgroundImage:"url(" + require(`./static/images/enyro_3.png`)+ ")"}}>
						 
	    					<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> 
							
						 <div className="show-tab">
						 	<br /><br /><br /><br /><br /><br /><br />
						 </div>
						 <div className="adjest-tab">
						 	<br /><br /><br /> 
						 </div>
						</div>
    				</div> 
    			</div>
				
    			<div className="col-md-6 col-lg-7 pl-lg-5 pb-5">
					<Slide direction='up' triggerOnce duration={1000}>
    				<div className="row justify-content-start pb-3">
						<div className="col-md-12 heading-section ">
							<div className="">
							<br /><br />
							</div> 
							<p>A language independed full stack software engineer with the ability to handle any task, from front-end design to back-end coding. </p>
							<Fade cascade triggerOnce>
							<ul className="about-info mt-4 px-md-0 px-2">
								<li className="d-flex"><span>Name:</span> <span>Heartly Enyro Fernando</span></li>
								<li className="d-flex"><span>Date of birth:</span> <span>February 08, 2002</span></li>
								<li className="d-flex"><span>Country: </span> <span>Sri Lanka</span></li> 
								<li className="d-flex"><span>Website: </span> <span>www.enyrofernando.com</span></li>
								<li className="d-flex"><span>Email:</span> <span>enyrofernando@outlook.com</span></li>
								<li className="d-flex"><span>Phone 1: </span> <span>+94-77-481-9547</span></li>
								<li className="d-flex"><span>Phone 2: </span> <span>+94-75-481-9547</span></li> 
							</ul>
							</Fade>
						</div>
		        	</div>
					<div className="counter-wrap d-flex mt-md-3">
					<div className="text">
						<p className="mb-4"> 
						</p>
						<p><a href="static/EnyroFernando_resume.pdf" download className="btn btn-primary py-3 px-3">Download Resume</a></p>
						<div className="hide-mobile">
						<br /><br /> 
						</div>
					</div>
					
					</div> 
					</Slide>
	       	 	</div>
				
        </div>
    	</div>
    </section> 
	</Fade>
  )
}

export default About
