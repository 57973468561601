import React from 'react'
// import './static/css/home.css'
import { Slide,Fade,Zoom,Flip,Hinge,JackInTheBox,Roll,Rotate } from 'react-awesome-reveal';

const Home = () => {
  return (
    <section id="home-section" className="hero">
		<div className="home-slider  owl-carousel">
	      <div className="slider-item ">
	      	<div className="overlay"></div>
	        <div className="container">
	          <div className="row d-md-flex no-gutters slider-text align-items-end justify-content-end" data-scrollax-parent="true">
		          <div className="one-forth d-flex  align-items-center" data-scrollax=" properties: { translateY: '70%' }">
		          	<div className="text">
		          		<span className="subheading"><Fade triggerOnce>Hello, World!</Fade></span>
			            <h1 className="mb-4 mt-3"><Fade triggerOnce delay={1000} duration={500} cascade >I'm </Fade><span className='span'><Fade delay={2000} duration={500} cascade triggerOnce>Enyro Fernando</Fade></span></h1>
			            <Fade triggerOnce delay={5500}>
						<h2 className="mb-4"><span>A Full Stack Software&nbsp;Engineer</span></h2>
			            <p><a href="#about-section" className="btn btn-primary py-3 px-4">About Me</a> <a href="#contact-section" className="btn btn-white btn-outline-white py-3 px-4">Contact</a></p>
						</Fade>
		            </div>
		          </div>
	        	</div>
	        </div>
	      </div> 
	    </div>
		{/* <div className="intro-overlay"></div>	

		<div className="intro-content">
			<div className="row">

				<div className="col-12">

					<h5>Hello, World.</h5>
					<h1>I'm Juan Dela Cruz.</h1>

					<p className="intro-position">
						<span>Front-end Developer</span>
						<span>UI/UX Designer</span> 
					</p>

					<a className="button stroke smoothscroll" href="#about" title="">More About Me</a>

				</div>  
				
			</div>   		 		
		</div>  

		<ul className="intro-social">        
			<li><a href="#"><i className="fa fa-facebook"></i></a></li>
			<li><a href="#"><i className="fa fa-behance"></i></a></li>
			<li><a href="#"><i className="fa fa-twitter"></i></a></li>
			<li><a href="#"><i className="fa fa-dribbble"></i></a></li>
			<li><a href="#"><i className="fa fa-instagram"></i></a></li>
		</ul>        */}
    </section>
  )
}

export default Home
